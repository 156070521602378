import { getSeasonString } from "@/helper/util";
import { Season } from "@/models/Season";
import { League } from "@prisma/client";

interface getTeamDetailUrlPropps {
  season: Season;
  teamId: string;
  league: League;
}
export function getTeamDetailUrl({
  season,
  teamId,
  league,
}: getTeamDetailUrlPropps) {
  // Important: Teamid ist Season Team ID
  return `/${league.toLowerCase()}/teams/${getSeasonString(season)}/${teamId}`;
}

export const dynLink =
  "https://www.dyn.sport/sport_tabletennis?utm_source=tischtennis-bundesliga&utm_medium=media-direct&utm_campaign=C:DE_L:DE_SC:League_CT:CXT_ST:Tischtennis_G:FIX&utm_content=CT:CXT_CTD:tischtennis-bundesliga-RoS&utm_term=ST:Tischtennis_CF:Live";
